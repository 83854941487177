<template>
  <div class="search-food-input">
    <v-autocomplete
      v-bind="$attrs"
      v-model="foodId"
      :loading="loading"
      :items="items"
      :search-input.sync="search"
      :auto-select-first="true"
      :cache-items="true"
      item-text="title"
      item-value="id"
      @change="selectedFood"
      hide-no-data
      :disabled="disabled"
    />
  </div>
</template>

<script>
import Food from '@/models/Food'

import { debounce, isNumber, isObject } from 'lodash'

export default {
  name: 'SearchFoodInput2',

  props: {
    value: {
      type: [Number, Object],
    },

    isFood: {
      type: Boolean,
      default: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    excludes: {
      type: Array,
      default: () => {
        return []
      },
    },
    flavorBooster: {
      type: Boolean,
      default: false,
    },

    includeFoodSwaps: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      search: '',
      loading: false,
      items: [],
      foodId: this.value,
    }
  },

  computed: {
    withExcludes() {
      return !!this.excludes.length
    },
  },

  methods: {
    getValue(value) {
      this.value = value
    },

    async fetchData(self, search, page = 1) {
      if (self.loading) return

      self.loading = true

      const query = Food.page(page)
        .where('type', this.isFood ? 2 : 1) // Food
        .where('taken', 0)
        .where('completed', 1) // Show only completed

      if (search) {
        if (isNumber(search)) {
          query.where('id', search)
        } else if (search instanceof Food || isObject(search)) {
          if (search.id) {
            const { id } = search
            query.where('id', id)
          }
        } else {
          query.where('searchable', search)
        }
      }

      if (this.flavorBooster) {
        query.where('is_flavor_booster', true)
      }

      if (this.includeFoodSwaps) {
        query.include('foodSwaps')
      }

      query.where('taken', false)

      const { data } = await query.params({ limit: 100 }).get()
      this.items = data

      self.loading = false
    },

    selectedFood(food) {
      this.$emit('input', food)
    },
  },

  watch: {
    search(val) {
      val && debounce(this.fetchData, 600)(this, val)
    },

    foodId(value) {
      this.$emit('change', value)
      this.$emit('input', value)
    },

    value(val) {
      val && debounce(this.fetchData, 300)(this, val)
      this.foodId = this.value
    },
  },
}
</script>
