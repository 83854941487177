<template>
  <div>
    <div class="d-flex align-center px-5 py-3 ">
      <div>
        <template v-if="item.food_boosters">
          <span v-if="item.food_boosters.quantity >= 0.1">
            {{ item.food_boosters.quantity }} {{ item.food_boosters.unit }}
          </span>
        </template>

        <span class="ml-2"> {{ item.title }} </span>

        <span v-if="!item.food_boosters.quantity">
          , {{ item.food_boosters.unit }}
        </span>
      </div>

      <v-spacer class="px-4">
        <v-divider />
      </v-spacer>

      <div>
        <v-menu
          class="ml-auto"
          :position-y="100"
          rounded="lg"
          offset-y
          bottom
          left
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              class="ma-2"
              v-bind="attrs"
              v-on="on"
              :loading="loading"
              icon
            >
              <v-icon>{{ icons.options }}</v-icon>
            </v-btn>
          </template>

          <v-list class="pa-0">
            <v-list-item class="px-5" @click="$emit('edit', item)" link>
              <v-list-item-title>
                Edit Flavour Booster
              </v-list-item-title>
            </v-list-item>

            <v-divider />

            <v-list-item class="px-5" @click="deleteBooster" link>
              <v-list-item-title class="primary--text">
                Remove Flavour Booster
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
  </div>
</template>

<script>
import Booster from '@/models/Booster'
import Food from '@/models/Food'

import { mdiDotsVertical } from '@mdi/js'

export default {
  props: {
    item: {
      type: [Object],
      required: true,
    },
    food: {
      type: [Object],
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      icons: {
        options: mdiDotsVertical,
      },
    }
  },

  methods: {
    async deleteBooster() {
      this.loading = true

      // eslint-disable-next-line camelcase
      let { food_boosters } = this.item

      // eslint-disable-next-line camelcase
      let booster = new Booster({ ...food_boosters }).for(
        new Food({ ...this.item })
      )

      try {
        await booster.delete()

        setTimeout(() => {
          this.$emit('deleted', this.item)

          return (this.loading = false)
        }, 800)
      } catch ({ response }) {
        setTimeout(() => {
          return (this.loading = false)
        }, 800)
      }
    },
  },
}
</script>
