<template>
  <v-dialog v-model="show" width="550" eager>
    <v-form autocomplete="off" @submit.prevent="submit">
      <v-card class="mx-auto" outlined>
        <v-card-title class="headline">
          {{ create ? 'Add' : 'Edit' }} Flavour Booster
        </v-card-title>

        <v-divider />

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <search-food-input
                v-model="form.booster_id"
                label="Search Food Booster"
                :excludes="excludes"
                :error-messages="form.$getError('booster_id')"
                :hide-details="!form.$getError('booster_id')"
                flavor-booster
                outlined
                is-food
                flat
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.quantity"
                label="Quantity"
                type="number"
                :error-messages="form.$getError('quantity')"
                :hide-details="!form.$getError('quantity')"
                outlined
                flat
              />
            </v-col>

            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.unit"
                :error-messages="form.$getError('unit')"
                :hide-details="!form.$getError('unit')"
                label="Unit"
                outlined
                flat
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider />

        <v-card-actions class="ma-2">
          <v-spacer />
          <v-btn
            class="px-10"
            color="primary"
            @click="cancel"
            :loading="form.$busy"
            text
          >
            Cancel
          </v-btn>

          <v-btn
            class="primary px-12"
            @click="submit"
            :loading="form.$busy"
            text
          >
            {{ create ? 'Add Booster' : 'Save Booster' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import SearchFoodInput from '@/components/elements/foods/SearchFoodInput2'
import Booster from '@/models/Booster'
import Food from '@/models/Food'
import Form from '@/utils/form'

import { mask } from 'vue-the-mask'

export default {
  name: 'FlavourBoosterDialog',

  components: {
    SearchFoodInput,
  },

  directives: {
    mask,
  },

  props: {
    formData: {
      type: Object,
      required: true,
    },

    excludes: {
      type: Array,
      default: () => {
        return []
      },
    },

    food: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      show: false,
      create: false,
      form: new Form({
        ...this.formData,
      }),
    }
  },

  computed: {
    meal() {
      return new Food(this.food)
    },
  },

  methods: {
    add() {
      this.show = true
      this.create = true
    },

    edit() {
      this.show = true
      this.create = false
    },

    open(create = true) {
      this.show = true
      this.create = create
    },

    close() {
      this.show = false
    },

    async submit() {
      this.form.$busy = true
      this.form.$clearErrors()

      // eslint-disable-next-line camelcase
      let { food_booster_id, booster_id, quantity, unit, id } = this.form

      let data = {
        food_booster_id,
        booster_id,
        quantity,
        unit,
      }

      if (!this.create) {
        data = { ...data, id }
      }

      let booster = new Booster(data).for(this.meal)

      try {
        booster = await booster.save()

        this.form.$busy = false

        if (this.create) {
          this.$emit('stored', booster)
        } else {
          this.$emit('updated', booster)
        }

        return this.close()
      } catch ({ response }) {
        setTimeout(() => {
          if (response && response.status === 422) {
            this.form.$setErrors(response.data.errors)
          }

          this.form.$busy = false
        }, 800)
      }
    },

    cancel() {
      this.$emit('cancelled')
      this.close()
    },
  },

  watch: {
    formData() {
      this.form = new Form({ ...this.formData })
    },
  },
}
</script>
