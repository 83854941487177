<template>
  <div class="bbr-exercise--flavour-boosters pt-10">
    <v-row>
      <v-col :md="8">
        <div class="d-flex justify-space-between align-center mb-4">
          <h2>Flavour Boosters</h2>

          <v-btn
            class="ml-auto px-8 primary--text"
            @click="$refs.booster.addFlavourBooster()"
            depressed
            text
          >
            <v-icon class="mr-3">
              {{ icons.add }}
            </v-icon>

            Add Flavour Booster
          </v-btn>

          <v-btn
            class="bg-primary-gradient primary next-button px-12 ml-2"
            :to="{
              name: 'form.recipe.directions',
              params: { id: $attrs.id },
            }"
            text
            depressed
          >
            Next
            <v-icon> {{ icons.next }} </v-icon>
          </v-btn>
        </div>

        <div class="flavour-boosters-list mt-8">
          <div class="flavour-boosters-list--container pt-5">
            <flavour-booster-list
              ref="booster"
              :food="food"
              v-on="$listeners"
            />
          </div>
        </div>
      </v-col>
    </v-row>

    <confirm-dialog ref="confirmSave">
      <template v-slot:cancel>
        <v-btn color="grey" text @click.native="$refs.confirmSave.cancel()">
          No
        </v-btn>
      </template>
    </confirm-dialog>

    <toast ref="toast" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mdiPlus, mdiArrowRight, mdiTrashCanOutline } from '@mdi/js'
import { pick, get } from 'lodash'

import FlavourBoosterList from '@/components/elements/foods/boosters/FlavourBoosterList'
import ConfirmDialog from '@/components/modals/ConfirmDialog'
import Toast from '@/components/elements/Toast'
import Form from '@/utils/form'

export default {
  name: 'RecipeFlavourBoostersTab',

  components: { FlavourBoosterList, ConfirmDialog, Toast },

  data() {
    return {
      flavourBoosters: [],
      addNewFlavourBooster: false,
      updateable: -1,
      flavour_booster: '',
      loading: false,
      form: new Form({
        id: null,
        title: null,
        flavour_boosters: [],
        raw: {
          food_id: null,
        },
      }),
      icons: {
        add: mdiPlus,
        next: mdiArrowRight,
        remove: mdiTrashCanOutline,
      },
    }
  },

  computed: {
    ...mapState({
      food: (state) => state.recipe.selectedData,
    }),

    hasFlavourBoosters() {
      return this.flavourBoosters ? !!this.flavourBoosters.length : false
    },
  },

  methods: {
    ...mapActions({
      getFood: 'recipe/getSingleData',
      saveData: 'recipe/saveData',
    }),

    async fetchData(id) {
      this.loading = true

      await this.getFood(id)

      this.flavourBoosters = this.food.boosters || []
      this.fillForm()

      this.loading = false
    },

    async saveChanges() {
      this.form.$busy = true
      this.form.flavour_boosters = this.flavourBoosters
      this.clear()

      await this.saveData(this.form.$data())

      await this.$sleep()

      this.form.$busy = false

      this.$refs.toast.open({
        color: 'green',
        message: 'Changes succesfully saved!',
      })
    },

    removeFlavourBooster(listKey) {
      this.flavourBoosters = this.flavourBoosters.filter(
        (booster, key) => listKey !== key
      )
    },

    edit(key, flavourBooster) {
      this.addNewFlavourBooster = true
      this.flavour_booster = flavourBooster
      this.updateable = key
    },

    store() {
      this.flavourBoosters = this.flavourBoosters
        .splice(0)
        .filter((_, key) => key !== this.updateable)
        .concat(this.flavour_booster)

      this.clear()
    },

    clear() {
      this.flavour_booster = ''
      this.addNewFlavourBooster = false
    },

    fillForm() {
      let data = pick(this.food, ['id', 'title', 'flavour_boosters', 'raw'])

      data.raw = {
        food_id: get(data, 'raw.id', null),
        title: get(data, 'raw.title', null),
        serving_quantity: get(data, 'raw.serving_quantity', null),
        serving_unit: get(data, 'raw.serving_unit', null),
      }

      this.form = new Form(data)
    },
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.$attrs.id) {
        vm.fetchData(vm.$attrs.id)
      }
    })
  },
}
</script>

<style lang="scss">
.flavour-boosters-list {
  ul {
    padding-left: 0;

    &:hover {
      li:not(:hover) {
        opacity: 0.5;
      }
    }

    li {
      list-style-type: none;
      padding-bottom: 30px;
      opacity: 1;

      .btn-remove {
        visibility: hidden;
      }

      .spacer {
        .v-divider {
          visibility: hidden;
        }
      }

      &:hover {
        color: var(--v-primary-base);
        cursor: pointer;

        .btn-remove {
          visibility: visible;
        }

        .spacer {
          .v-divider {
            visibility: visible;
          }
        }
      }
    }
  }
}
</style>
