<template>
  <div v-bind="$attrs">
    <div v-if="hasFlavourBoosters">
      <flavour-booster-item
        v-for="booster in boosters"
        :item="booster"
        :food="food"
        :key="booster.id"
        @edit="editFlavourBooster"
        @deleted="deletedBooster"
      />
    </div>

    <no-list v-else details="No Flavour Boosters" />

    <slot name="add-button" />

    <flavour-booster-dialog
      ref="flavourBoosterDialog"
      :formData="booster"
      :excludes="boosters"
      :food="food"
      @stored="boosterAdded"
      @updated="boosterUpdated"
    />
  </div>
</template>

<script>
import FlavourBoosterDialog from '@/components/modals/foods/FlavourBoosterDialog'
import FlavourBoosterItem from './FlavourBoosterItem'
import NoList from '@/components/elements/NoList'
import Form from '@/utils/form'

import { mdiPlus, mdiPencil, mdiTrashCanOutline } from '@mdi/js'
import { get, findIndex } from 'lodash'

export default {
  name: 'FlavourBoosterList',

  components: {
    FlavourBoosterDialog,
    FlavourBoosterItem,
    NoList,
  },

  props: {
    food: Object,
  },

  data() {
    return {
      booster: {
        food_booster_id: null,
        booster_id: null,
        quantity: null,
        unit: null,
      },

      boosters: [],
      icons: {
        add: mdiPlus,
        edit: mdiPencil,
        delete: mdiTrashCanOutline,
      },
    }
  },

  computed: {
    hasFlavourBoosters() {
      return !!this.boosters.length
    },
  },

  created() {
    this.boosters = get(this.food, 'boosters', [])
  },

  methods: {
    addFlavourBooster() {
      this.booster = {
        booster_id: null,
        quantity: null,
        unit: null,
      }

      this.$refs.flavourBoosterDialog.open()
    },

    boosterAdded(booster) {
      this.boosters.push(booster)
    },

    editFlavourBooster(booster) {
      // eslint-disable-next-line camelcase
      const { food_boosters } = booster

      this.booster = new Form({
        booster_id: booster.id,
        unit: food_boosters.unit,
        quantity: food_boosters.quantity,
        id: food_boosters.id,
      })

      this.$refs.flavourBoosterDialog.edit()
    },

    boosterUpdated(booster) {
      const boosterIndex = findIndex(this.boosters, { id: booster.id })

      this.boosters.splice(boosterIndex, 1, booster)
    },

    deletedBooster(booster) {
      const boosterIndex = findIndex(this.boosters, { id: booster.id })

      this.boosters.splice(boosterIndex, 1)
    },
  },

  watch: {
    food() {
      this.boosters = get(this.food, 'boosters', [])
    },
  },
}
</script>
